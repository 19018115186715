<template>
  <div class="licence-choice">
    <div
      class="licence-content"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    >
      <div>
        <strong class="duration text-regular">
          {{ offer.duree_verbose }}
          <template v-if="!compatibleAdoptant && categorie !== 21">
            {{ offer.adoptant ? $t('offre.adoptant') : $t('offre.non-adoptant') }}
          </template>
          <a
            v-if="infoLink"
            :href="`#offre_${offer.id}`"
          >
            <UilInfoCircle />
          </a>
        </strong>
      </div>
      <div
        v-if="offer.prescripteur"
        class="prices"
      >
        <span class="text-medium bold">{{ $t('offre.offre-offerte-editeur') }}</span>
      </div>
      <template v-else>
        <div class="prices">
          <span
            v-if="offer.prix_editeur !== offer.prix_ht"
            class="text-medium bold all-taxes"
            v-html="$t('prix.ttc', { prix: moneyFilter(offer.prix_editeur), })"
          />
          <span
            class="text-medium duty-free"
            v-html="$t('prix.ht', { prix: moneyFilter(offer.prix_ht), })"
          />
        </div>
        <InputQuantity
          v-model="quantity"
          :status="offer.quantite < offer.quantite_min ? 'warning' : 'default'"
          :shadow="hovered && isOfferAvailable(offer)"
          :disabled="!isOfferAvailable(offer)"
        />
        <div class="select-btn-container">
          <div
            v-tooltip="{
              content: $t('produit.article-n-est-pas-disponible'),
              disabled: isOfferAvailable(offer),
            }"
            class="zone-liste-panier"
          >
            <ButtonClassic
              class="select-btn"
              variant="special"
              :label="$t('liste.ajouter-a-ma-liste')"
              color="primary"
              icon="left"
              :disabled="isImpersonating || !hasPerm('add_lignedeliste') || !isOfferAvailable(offer) || !quantity"
              @click="$emit('add-to-list')"
            >
              <template #left-icon>
                <InfiniteLoader v-if="isFetchingActiveLists" />
                <UilBookmark v-else />
              </template>
            </ButtonClassic>
            <ButtonClassic
              variant="solid"
              :label="$t('panier.au-panier')"
              color="secondary"
              icon="right"
              size="large"
              :disabled="isImpersonating || !hasPerm('add_lignedeliste') || !isOfferAvailable(offer) || !quantity"
              @click="
                $store.dispatch(
                  'addOffersToPanier',
                  { lignes: [{ id_offre: offer.id, quantite: quantity, }, ], }
                )
              "
            >
              <template #right-icon>
                <IconPanier />
              </template>
            </ButtonClassic>
          </div>
        </div>
      </template>
    </div>
    <p
      v-if="(!quantity || quantity < offer.quantite_min) && offer.quantite_min > 0"
      class="text-small italic minima-text flex-vcenter"
    >
      <UisExclamationTriangle size="16" />
      {{ $t("produit.minima-a-x", { minima: offer.quantite_min, }) }}
    </p>
  </div>
</template>

<script>
import {
  ButtonClassic,
  InputQuantity,
  moneyFilter,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import { UilInfoCircle, UilBookmark } from "@iconscout/vue-unicons";
import { UisExclamationTriangle } from "@iconscout/vue-unicons-solid";
import IconPanier from "@/components/icons/IconPanier.vue";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { mapGetters } from "vuex";

/**
 * Propose un choix de licences avec la quantité voulue.
 */
export default {
  name: "LicenceChoice",
  components: {
    ButtonClassic,
    InputQuantity,
    InfiniteLoader,
    UilInfoCircle,
    UilBookmark,
    UisExclamationTriangle,
    IconPanier,
  },
  mixins: [mixinDisponibilite],
  model: {
    prop: "offer",
    event: "change",
  },
  props: {
    /**
     * Offre affichée.
     */
    offer: {
      type: Object,
      required: true,
    },
    /**
     * Affiche un lien sous forme de logo d'information redirigeant vers le détail de la licence.
     */
    infoLink: {
      type: Boolean,
      default: false,
    },
    /**
     * Code de la catégorie du produit.
     */
    categorie: {
      type: Number,
      required: true,
    },
  },
  emits: [
    /**
     * Déclenché au clic sur le bouton de sélection.
     */
    "add-to-list",
  ],
  data() {
    return {
      hovered: false,
      quantityValue: this.offer.quantite,
    };
  },
  computed: {
    ...mapGetters(["hasPerm", "compatibleAdoptant", "isFetchingActiveLists"]),
    quantity: {
      get() {
        return this.quantityValue;
      },
      set(newValue) {
        this.quantityValue = newValue;
        this.$set(this.offer, "quantite", newValue);
      },
    },
  },
  methods: {
    moneyFilter,
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/products/licence_choice.scss";
</style>
